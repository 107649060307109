import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { PropsWithChildren } from "react";

const PageContainerBoxStyle = { 
  height: "100%", 
  flexDirection: "column", 
  padding: "22px 32px 32px 32px" 
};

const ButtonsBarBoxStyle = {
  width: "100%", 
  height: "40px", 
  display: "flex", 
  justifyContent: "space-between", 
  gap: 4, 
  alignItems: "center"
};

type PageContentButtonProps = {
  handler?: () => void;
  disabled?: boolean;
  text?: string;
  hide?: boolean;
  loading?: boolean;
  downloadVariant?: boolean;
};

type PageContentProps = PropsWithChildren & {
  title?: string;
  subtitle?: string;
  showButtonBar?: boolean;
  nextButton?: PageContentButtonProps,
  backButton?: PageContentButtonProps,
};

export const PageContent = ({
  nextButton,
  backButton,
  children,
  title,
  subtitle,
  showButtonBar = true,
}: PageContentProps) => {
  const childrenHeight = showButtonBar
    ? "calc(100% - 40px)" 
    : "100%";

  return (
    <Box sx={PageContainerBoxStyle}>
      <Box sx={{ height: "calc(100% - 40px)" }}>
        <Box sx={{ fontSize: "30px" }} data-testid="title-wrapper">
          {title}
        </Box>
        {subtitle && (
          <Typography sx={{ paddingTop: "10px" }}>
            {subtitle}
          </Typography>
        )}
        <Box sx={{ height: childrenHeight, overflow: "auto" }}>
          {children}
        </Box>
      </Box>

      {showButtonBar && (
        <Box sx={ButtonsBarBoxStyle} className="w-full flex flex-row justify-between h-[40px] gap-4 items-center">
          {!backButton?.hide && (
            <Button
              disabled={backButton?.disabled}
              sx={{ width: "240px" }}
              variant="outlined"
              data-testid="back-button"
              onClick={backButton?.handler}
            >
              {backButton?.text ?? 'Go Back'}
            </Button>
          )}

          {!nextButton?.hide && (!nextButton?.downloadVariant ? (
            <Button
              variant="contained"
              sx={{
                width: "240px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={nextButton?.disabled}
              onClick={nextButton?.handler}
              data-testid="next-button"
            >
              <Box>{nextButton?.text ?? "Next"}</Box>
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                width: "240px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={nextButton?.disabled}
              onClick={nextButton?.handler}
              data-testid="next-button"
            >
              <Box className="text-right" sx={{ flex: 2 }}>
                {nextButton?.text ?? "Next"}
              </Box>
              {nextButton?.loading ? (
                <Box className="h-full content-center flex" sx={{ flex: 1 }}>
                  <CircularProgress className={"!text-[#99A8A8A8]"} size={16} />
                </Box>
              ) : (
                <Box className="h-full" sx={{ flex: 1 }}></Box>
              )}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};
