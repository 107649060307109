/* eslint-disable  @typescript-eslint/no-explicit-any */
import Papa from "papaparse";
import DataFrame from "dataframe-js";
import { verifyCorrectStructure } from "../dashboard/uploadVerifications";
import { apiHelper, ApiResponse } from "../../apis/apiHelper";
import * as amplify from "@aws-amplify/auth";
import AWS, { S3 } from "aws-sdk";
import {
  updateUploadCount,
  userCanUpload,
} from "../../utils/uploadLimitHelpers";
import { getAWSConfig } from "../../utils/credentialsHelper";
import { getCurrentUser } from "@aws-amplify/auth";
import { ModelConfigs } from "../AnalysisPage/types";
import { columnMapper } from "../dashboard/uploadHelper";

type CsvRow = {
  [key: string]: string;
};

export async function readFileSliceAsText(slice: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(slice);
  });
}

export const readCsv = async (slice: Blob) => {
  const fileText = await readFileSliceAsText(slice);

  return Papa.parse(fileText as string, {
    dynamicTyping: true,
    header: true,
    skipEmptyLines: true,
  }).data as CsvRow[];
};

export const verifyFile = async (file: File) => {
  const firstChunkBytes = 1;
  const firstChunkSize = firstChunkBytes * 1024 * 1024;
  const fileSize = file.size;
  const slice = file.slice(0, Math.min(firstChunkSize, fileSize));
  const maxFileSize = 2 * 1024 * 1024 * 1024;
  const errorMessages: string[] = [];
  const acceptedExtensions = ["csv"];
  const extension = file.name.split(".").pop()?.toLowerCase();
  if (!acceptedExtensions.includes(extension as string)) {
    throw `Error in ${file.name}:\nThe file extension must be .csv`;
  }
  const data: CsvRow[] = await readCsv(slice);

  if (data && data.length > 0) {
    const df = new DataFrame(data);
    const beginningVerification = await verifyCorrectStructure(df);

    if (!beginningVerification.result) {
      errorMessages.push(
        `Error in ${file.name}:\nThe following columns could ` +
          "not be identified in the uploaded file: \n    * " +
          beginningVerification.failingChecks.join("\n    * ")
      );
    }
    if (file.size > maxFileSize) {
      errorMessages.push(`Error in ${file.name}:\nExceeds the limit of 2GB`);
    }
  } else {
    errorMessages.push(
      `Error in ${file.name}:\n No bolting data found in the file.`
    );
  }

  if (errorMessages.length > 0) {
    throw errorMessages.join("\n");
  }
};
export const fetchPresignedUrl = async (Filename: string): Promise<string> => {
  try {
    const userId = (await amplify.getCurrentUser())["userId"];
    const uploadBucketName =
      process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";

    // Make API call to trigger Lambda conversion
    const response = await apiHelper.post(
      `${process.env.REACT_APP_REST_API_ROOT_URL}/create-json`,
      {
        "user-id": userId,
        "bucket-name": uploadBucketName,
        "file-key": `raw/${Filename}`,
        "job-id": 1,
      }
    );
    if (response.statusCode === 200) {
      const data = JSON.parse(response.body as string);

      const presignedUrl = data.presigned_url as string;

      if (presignedUrl) {
        return presignedUrl;
      } else {
        throw new Error("Failed to retrieve presigned URL");
      }
    } else {
      throw new Error(`API request failed with status ${response.statusCode}`);
    }
  } catch (err) {
    console.error("Error triggering Lambda or downloading JSON:", err);
    throw err;
  }
};

export const downloadFromPresignedUrl = async (presignedUrl: string) => {
  const jsonResponse = await fetch(presignedUrl);
  const jsonData = await jsonResponse.json();
  if (jsonData) {
    return jsonData;
  }
  return {};
};


export const uploadFile = (
  file: File | null,
  setFileName: (fileName: string) => void,
  setUploadHandler: (uploadHandler: S3.ManagedUpload) => void,
  setShowUploadModal: (showUploadModal: boolean) => void,
  setProgress: (progress: number) => void,
  setLines: (lines: any[]) => void
) => {
  return async () => {
    if (!(await userCanUpload())) {
      alert("User has already reached upload limit!");
      return;
    }

    if (!file) {
      console.error("No file to upload.");
      alert("No file selected for upload.");
      return;
    }

    const s3 = new AWS.S3({
      credentials: (await getAWSConfig()).credentials,
      apiVersion: "2006-03-01",
    });

    try {
      const uploadBucketName =
        process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";
      const params = {
        Bucket: uploadBucketName,
        Key: `raw/${file.name}`,
        Body: file,
      };
      setFileName(file.name);
      const managedUpload = s3.upload(params);

      setUploadHandler(managedUpload);
      setShowUploadModal(true);

      managedUpload.on("httpUploadProgress", function (evt) {
        const percentCompleted = Math.round((evt.loaded / evt.total) * 100);

        setProgress(percentCompleted);
      });

      await managedUpload.promise();
      updateUploadCount(1);
      clearLocalStorage();

      const presigned_url = await fetchPresignedUrl(file.name);
      const jsonData = await downloadFromPresignedUrl(presigned_url);
      const fileLines = jsonData.map(columnMapper);
      setLines(fileLines);
    } catch (err) {
      if ((err as Error).name === "RequestAbortedError") {
        alert("Upload cancelled");
      } else {
        console.error("Error uploading file:", err);
        alert("Failed to upload file.");
      }
    }
  };
};

export const clearLocalStorage = () => {
  // clear locally stored data
  const keysToDelete: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith("boltingData_")) {
      keysToDelete.push(key);
    }
  }

  keysToDelete.forEach(key => {
    localStorage.removeItem(key);
  });
};

export const createModelConfig = async ({
  tool_id,
  model_name,
  process_id,
  target_torque,
  target_angle,
}: {
  tool_id: string;
  model_name: string;
  process_id: string;
  target_torque: string;
  target_angle?: string | null;
}) => {
  const user_id = (await getCurrentUser())["userId"];
  const url =
    process.env.REACT_APP_REST_API_ROOT_URL +
    "/configurations/create-model-config";

  const response = await apiHelper.post(url, {
    user_id,
    tool_id,
    model_name,
    process_id,
    target_torque,
    target_angle,
  });

  return response;
};

export const fetchModelConfigs = async () => {
  const user_id = (await getCurrentUser())["userId"];

  const url =
    process.env.REACT_APP_REST_API_ROOT_URL +
    "/configurations/get-model-configs";

  const response: ApiResponse = await apiHelper.post(url, {
    user_id,
  });

  if (response && "model_configs" in response) {
    return response["model_configs"] as ModelConfigs;
  } else {
    throw new Error("Model configs not found in response");
  }
};

export const verifyModelConfig = async (configName: string) => {
  try {
    const user_id = (await getCurrentUser())["userId"];
    const url =
      process.env.REACT_APP_REST_API_ROOT_URL +
      "/configurations/verify-model-config";

    await apiHelper.post(url, {
      user_id,
      model_name: configName,
    });
  } catch (err) {
    console.error(err);
    throw new Error("Error verifying model");
  }
};
