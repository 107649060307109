import * as z from 'zod';
import { ModelConfigs } from './types';

const DEFAULT_ERROR = 'Please enter a valid name (letters and numbers only + underscore). Maximum 30 characters';

export const prepareAnalysisSchema = (
  modelConfigs: ModelConfigs
) => z.object({
  toolId: z
    .string({
      required_error: 'ToolId is required',
      invalid_type_error: 'Incorrect'
    })
    .regex(
      /[A-Za-z0-9\s_]+/,
      { message: DEFAULT_ERROR }
    ),
  processId: z
    .string({
      required_error: 'ProcessId is required',
      invalid_type_error: 'Incorrect'
    })
    .regex(
      /[A-Za-z0-9\s_]+/,
      { message: DEFAULT_ERROR }
    ),
  configName: z
    .string({
      required_error: 'Configuration Name is required',
      invalid_type_error: 'Incorrect',
    })
    .min(3)
    .regex(
      /[A-Za-z0-9\s_]{1,30}/,
      { message: DEFAULT_ERROR }
    )
    .refine(
      async (val) => !(val in modelConfigs),
      { message: 'Model already exists' }
    ),
  targetTorque: z
    .string({
      required_error: 'Target Torque is required',
      invalid_type_error: 'Incorrect',
    })
    .regex(
      /[A-Za-z0-9\s_.,]+/,
      { message: DEFAULT_ERROR }
    ),
  targetAngle: z.string().nullable()
});

export type PrepareAnalysisSchema = z.infer<ReturnType<typeof prepareAnalysisSchema>>;
