import LoadingScreenProgressBar from "../loadingScreen/progressBar";
import "./style.scss";
import { memo } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { tertiaryColors } from "../../theme/DarkTheme";

const ModalStyle = {
  display: 'flex',
  justifyContent: "center",
  alignItems: "center"
};

const ModalBoxStyle = {
  bgcolor: tertiaryColors.dark,
  padding: "18px 0 0 32px",
  borderRadius: "12px",
  width: "560px",
  height: "360px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

type UploadDialogProps = {
  uploadProgress: number;
  handleCancelUpload: () => void;
};

function UploadDialog({
  uploadProgress,
  handleCancelUpload
}: UploadDialogProps) {
  const innerCancelCallback = () => {
    if (uploadProgress !== 100) {
      handleCancelUpload();
    }
  };

  return (
    <Modal
      open={true}
      onClose={innerCancelCallback}
      sx={ModalStyle}
    >
      <Box sx={ModalBoxStyle}>
        <Typography
          sx={{ fontSize: "24px" }}
          variant="h5"
          component="div"
          fontWeight={100}
        >
          Your file is uploading
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "start" }}>
          <Box sx={{ flexBasis: "30%" }}>
            <LoadingScreenProgressBar value={uploadProgress / 100} />
          </Box>

          <Box
            sx={{
              flexBasis: "70%",
              alignContent: "center",
              fontSize: "20px",
              marginLeft: "40px"
            }}
          >
            <Typography
              fontWeight={100}
              sx={{ fontSize: "16px", paddingBottom: "10px" }}
            >
              Your upload is being processed.
            </Typography>

            <Typography
              fontWeight={100}
              fontSize={16}
            >
              Soon you will be redirected
            </Typography>

            <Typography
              fontWeight={100}
              sx={{ fontSize: "16px", paddingTop: "10px" }}
            >
              to use bolt_it for your analysis.
            </Typography>
          </Box>
        </Box>

        <Button
          sx={{
            justifyContent: "end",
            padding: "0px 42px 24px 0",
            fontSize: "16px"
          }}
          variant="text"
          onClick={innerCancelCallback}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}

export default memo(UploadDialog);
