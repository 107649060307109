import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

export type AnalysisProvider = {
  file: File | null;
  setFile: (_file: File | null) => void;
  execute: {
    toolId: string | null;
    processId: string | null;
    configName: string | null;
    targetTorque: string | null;
    targetAngle: string | null;
  };
  setExecute: (
    key: keyof typeof initState.execute,
    value: string | null
  ) => void;
  setPrepare: (
    key: keyof typeof initState.prepare,
    value: string | null
  ) => void;
  prepare: {
    toolId: string;
    processId: string;
    configName: string;
    targetTorque: string;
    targetAngle: string;
  };
  setPrepareObj: (obj: typeof initState.prepare) => void;
  setExecuteObj: (obj: typeof initState.execute) => void;
  cleanExecute: () => void;
  cleanPrepare: () => void;
};

export const initState: AnalysisProvider = {
  file: null,
  setFile: () => null,
  execute: {
    toolId: null,
    processId: null,
    configName: null,
    targetTorque: null,
    targetAngle: null,
  },
  setExecute: () => null,
  prepare: {
    toolId: '',
    processId: '',
    configName: '',
    targetTorque: '',
    targetAngle: '',
  },
  setPrepare: () => null,
  setPrepareObj: () => null,
  setExecuteObj: () => null,
  cleanExecute: () => null,
  cleanPrepare: () => null,
};

const AnalysisContext = createContext(initState);

export const useAnalysisContext = () => {
  const context = useContext(AnalysisContext);

  return context;
};

export const AnalysisProvider = ({ children }: PropsWithChildren) => {
  const [file, setFile] = useState<File | null>(initState.file);
  const [execute, setExecute] = useState(initState.execute);
  const [prepare, setPrepare] = useState(initState.prepare);

  const setOutterExecute = (
    key: keyof typeof initState.execute,
    value: string | null
  ) => {
    setExecute({ ...execute, [key]: value });
  };
  
  const setOutterPrepare = (
    key: keyof typeof initState.prepare,
    value: string | null
  ) => {
    setPrepare({ ...prepare, [key]: value });
  };

  const setPrepareObj = (obj: typeof initState.prepare) => {
    if (JSON.stringify(obj) !== JSON.stringify(prepare)) {
      setPrepare(obj);
    }
  };

  const setExecuteObj = (obj: typeof initState.execute) => {
    if (JSON.stringify(obj) !== JSON.stringify(execute)) {
      setExecute(obj);
    }
  };

  const cleanExecute = () => {
    setFile(null);
    setExecute(initState.execute);
  };
  
  const cleanPrepare = () => {
    setFile(null);
    setPrepare(initState.prepare);
  };

  const value = useMemo(() => {
    return {
      file,
      setFile,
      prepare,
      setPrepare: setOutterPrepare,
      execute,
      setExecute: setOutterExecute,
      setPrepareObj,
      setExecuteObj,
      cleanExecute,
      cleanPrepare
    };
  }, [
    file,
    setFile,
    prepare,
    setOutterPrepare,
    execute,
    setOutterExecute,
    setPrepareObj,
    setExecuteObj,
    cleanExecute,
    cleanPrepare
  ]);

  return (
    <AnalysisContext.Provider value={value}>
      {children}
    </AnalysisContext.Provider>
  );
};
