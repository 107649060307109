import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { LabellingTable } from "./LabellingTable";
import {
  UpdateBoltingDataFeedbackApiBody,
  useUpdateBoltingDataFeedback,
} from "../../apis/useSaveBoltingDataFeedback";
import { useEffect, useMemo, useState } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import { verifyModelConfig } from "../upload/uploadHelpers";
import "./LabellingPage.scss";
import { Stepper } from "../dashboard/Stepper";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";
import { Box, Modal, Typography } from "@mui/material";
import { PageContent } from "../../components/PageContent/PageContent";
import { tertiaryColors } from "../../theme/DarkTheme";
import { useAnalysisContext } from "../../providers/AnalysisProvider";

const ModalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ModalBoxStyle = {
  bgcolor: tertiaryColors.dark,
  gap: "12px",
  padding: "32px",
  textAlign: "center",
  borderRadius: "24px",
  width: "440px",
  height: "440px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const LabellingPage = () => {
  const [saveAndContinueDisabled, setSaveAndContinueDisabled] = useState(false);
  const [unlabelledDataFound, setUnlabelledDataFound] = useState(false);
  const [loadedAllData, setLoadedAllData] = useState(false);
  const navigate = useNavigate();

  const { cleanPrepare, cleanExecute } = useAnalysisContext();
  const { mutate: updateFeedback } = useUpdateBoltingDataFeedback({
    onSuccess: () => {
      setTimeout(() => {
        cleanPrepare();
        cleanExecute();
        navigate("/analysis");
      }, 10000);
    },
  });
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName");
  const configName = searchParams.get("configName");

  const [showDialog, setShowDialog] = useState(false);
  const { isExecuteTabAnalysis } = useStepperContext();

  const feedbackData: UpdateBoltingDataFeedbackApiBody = useMemo(() => {
    return {
      configName: configName ?? "config1",
    };
  }, [configName]);

  useEffect(() => {
    if (loadedAllData && !unlabelledDataFound) {
      setSaveAndContinueDisabled(false);
    }
  }, [loadedAllData, unlabelledDataFound]);

  const onSaveAndContinueClick = () => {
    if (!saveAndContinueDisabled) {
      setShowDialog(true);
      updateFeedback(feedbackData);
      verifyModelConfig(configName ?? "config1");
    }
  };

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  return (
    <PageContent
      showButtonBar={true}
      title="Your feedback is required"
      subtitle="Please provide feedback to all predictions. The better the feedback,the better the analysis in the end."
      nextButton={{
        handler: onSaveAndContinueClick,
        disabled: saveAndContinueDisabled,
        text: "Submit Feedback"
      }}
      backButton={{
        handler: () =>
          navigate(
            `/data-check?fileName=${fileName}&configName=${configName}&lastStep=labelling`
          ),
      }}
    >
      <Box className="h-full">
        <Box className={showDialog ? "blur-background" : "" + " h-[80%]"}>
          <Box className="h-[15%]">
            <Stepper
              steps={
                isExecuteTabAnalysis
                  ? MLFlowStepperLabelsExecuteTab
                  : MLFlowStepperLabelsAnalysisTab
              }
              currentStep={3}
              shouldShowStepper={true}
            />{" "}
          </Box>
          <Box className="h-[80%]">
            <LabellingTable
              className="h-full"
              onArrowClick={row => navigate(`/labelling/${row.id}`)}
              setUnlabelledDataFound={setUnlabelledDataFound}
              setLoadedAllData={setLoadedAllData}
            />
          </Box>
        </Box>
      </Box>

      <Modal open={showDialog} sx={ModalStyle}>
        <Box sx={ModalBoxStyle}>
          <Typography variant="h5" className="flex justify-center pt-24">
            Thank you for your feedback.
          </Typography>

          <Typography sx={{ marginTop: "20px" }}>
            In a moment, you’ll be able to either use the trained AI model for
            analysis or seamlessly train a new one.
          </Typography>
        </Box>
      </Modal>
    </PageContent>
  );
};
