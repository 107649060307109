import React from "react";
import ReactDOM from "react-dom/client";

import { ResultsProvider } from "./providers/resultsProvider";
import { ReactQueryProvider } from "./providers/ReactQueryProvider";
import { StepperProvider } from "./providers/StepperProvider";
import { CSVProvider } from './providers/CSVProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/DarkTheme";
import App from "./App";

import './theme/designit.scss';
import "./index.scss";
import { MenuProvider } from "./providers/MenuProvider";
import { AnalysisProvider } from "./providers/AnalysisProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReactQueryProvider>
      <ResultsProvider>
        <CSVProvider>
          <Router>
            <StepperProvider>
              <AnalysisProvider>
                <ThemeProvider theme={theme}>
                  <MenuProvider>
                    <App />
                  </MenuProvider>
                </ThemeProvider>
              </AnalysisProvider>
            </StepperProvider>
          </Router>
        </CSVProvider>
      </ResultsProvider>
    </ReactQueryProvider>
  </React.StrictMode>
);	
