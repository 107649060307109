export const MLFlowStepperLabelsAnalysisTab: string[] = [
  'Configuration',
  'Validation',
  'Feedback'
];

export const MLFlowStepperLabelsExecuteTab: string[] = [
  'Configuration',
  'Validation',
  'Results'
];

export const NonMLFlowStepperLabel: string[] = [
  "Upload",
  "Analysis",
  "Results"
];
