import { createContext, PropsWithChildren, useContext, useState } from "react";

export type InitStateType = {
  isCollapsed: boolean;
  setIsCollapsed: (flag: boolean) => void;
};

const initState: InitStateType = {
  isCollapsed: false,
  setIsCollapsed: () => { },
};

export const MenuContext = createContext(initState);

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  return context;
};

export const MenuProvider = ({ children }: PropsWithChildren) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(initState.isCollapsed);

  return (
    <MenuContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed
      }}>
      {children}
    </MenuContext.Provider>
  );
};
