/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { BoltingData, DbBoltingData } from "../../apis/types";
import { ICellRendererParams } from "ag-grid-community";
import { DesignItProgress, DesignItTag } from "@design-it/react-library";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import { CustomCellRendererProps } from "ag-grid-react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { format } from "date-fns";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";

export enum FeedbackValues {
  NONE = "NONE",
  OK = "OK",
  NOK = "NOK",
}

type CustomHeaderProps = {
  icon?: React.ReactElement;
  title: string;
};

const CustomHeader = (props: CustomHeaderProps) => {
  return (
    <div className="custom-header">
      <span className="header-icon">{props.icon} </span>
      <span className="header-title">{props.title}</span>
    </div>
  );
};

export type ResultsTableProps = {
  onArrowClick?: (row: DbBoltingData) => void;
  data?: BoltingData[];
  isLoading: boolean;
  className?: string;
  hideArrow?: boolean;
};

export type RowViewTableProps = {
  onArrowClick?: (row: DbBoltingData) => void;
  data?: BoltingData[];
  isLoading: boolean;
  className?: string;
  hideArrow?: boolean;
  modelExists: boolean;
};

export type LabellingTableProps = {
  className?: string;
  onArrowClick?: (row: DbBoltingData) => void;
  setUnlabelledDataFound: (unlabelledDataFound: boolean) => void;
  setLoadedAllData: (loadedAllData: boolean) => void;
};

export const CustomHeaderBuilder = (
  title: string,
  icon: React.ReactElement
) => {
  return function CustomHeaderComponent(props: unknown) {
    return (
      <CustomHeader
        {...(props as CustomHeaderProps)}
        title={title}
        icon={icon}
      />
    );
  };
};

export const useIdCellRenderer = () => {
  return useCallback((props: ICellRendererParams) => {
    if (props.value !== undefined) {
      return `${props.data.productId}`;
    } else {
      return (
        <DesignItProgress
          style={{ "--md-circular-progress-size": "30px" }}
          indeterminate
        />
      );
    }
  }, []);
};

export const getLabellingTableColDefs = (
  idCellRenderer: any,
  onArrowClick: ((row: DbBoltingData) => void) | undefined
) => {
  const colDefs: any = [
    /**
     * Since something's off with the AgGrid column definitions, we're using any[] here.
     * Need to be refactored to use ColDef[] or ColGroupDef[].
     */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    {
      field: "productId",
      headerComponent: CustomHeaderBuilder(
        "Product ID",
        <Grid3x3Icon className="header-icon" fontSize="small" />
      ),
      flex: 2,
      cellClass: "custom-cell",
      cellRenderer: idCellRenderer,
    },
    {
      field: "processId",
      headerComponent: CustomHeaderBuilder(
        "Process ID",
        <Grid3x3Icon className="header-icon" fontSize="small" />
      ),
      flex: 5,
      cellClass: "custom-cell",
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return `${props.data.processId}`;
        }
      },
    },
    {
      field: "status",
      headerComponent: () => (
        <CustomHeader
          icon={
            <CheckCircleOutlineIcon className="header-icon" fontSize="small" />
          }
          title="Bolting Tool Result"
        />
      ),
      flex: 3,
      cellClass: "custom-cell",
      cellStyle: { justifyContent: "start" },
    },
    {
      field: "createdDate",
      headerComponent: () => (
        <CustomHeader
          icon={<AccessAlarmsIcon className="header-icon" fontSize="small" />}
          title="Timestamp Result"
        />
      ),
      cellStyle: { justifyContent: "start" },
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return format(new Date(props.data.createdDate), "dd.MM.yyyy | HH:mm");
        }
      },
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "feedback",
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return (
            <DesignItTag
              onClick={() => onArrowClick?.(props.data)}
              key={props.value}
              className="ics-green-light"
              variant={(() => {
                if (props.value === FeedbackValues.NOK) {
                  return "error";
                }
                if (props.value === FeedbackValues.OK) {
                  return "success";
                }
                return "surface";
              })()}
              icon-name={(() => {
                if (props.value === FeedbackValues.NOK) {
                  return "do_not_disturb_on";
                }
                if (props.value === FeedbackValues.OK) {
                  return "check_circle";
                }
                return "info";
              })()}
              icon-position="first"
            >
              {(() => {
                if (props.value === FeedbackValues.NOK) {
                  return "anomaly";
                }
                if (props.value === FeedbackValues.OK) {
                  return "no anomaly";
                }
                return "wanna check this";
              })()}
            </DesignItTag>
          );
        }
      },
      cellClass: "custom-cell",
    },
    {
      field: "anomalyDetected",
      headerComponent: () => (
        <CustomHeader
          icon={<HdrAutoIcon className="header-icon" fontSize="small" />}
          title="AI Result"
        />
      ),
      flex: 3,
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value === "yes") {
          return (
            <DesignItTag
              className="ics-green-light"
              variant="error"
              icon-name="do_not_disturb_on"
              icon-position="first"
            >
              anomaly
            </DesignItTag>
          );
        }

        if (props.value === "no") {
          return (
            <DesignItTag
              className="ics-green-light"
              variant="success"
              icon-name="check_circle"
              icon-position="first"
            >
              no anomaly
            </DesignItTag>
          );
        }
      },
      cellClass: "custom-cell",
    },
  ];

  return colDefs;
};

export const getResultTableColDefs = (
  idCellRenderer: any,
  anomalyCellRenderer: any,
  arrowCellRenderer: any,
  hideArrow: boolean
) => {
  /**
   * Since something's off with the AgGrid column definitions, we're using any[] here.
   * Need to be refactored to use ColDef[] or ColGroupDef[].
   */
  const colDefs: any[] = [
    {
      field: "productId",
      headerComponent: CustomHeaderBuilder(
        "ID",
        <Grid3x3Icon className="header-icon" fontSize="small" />
      ),
      flex: 4,
      cellClass: "custom-cell",
      valueGetter: "node.id",
      cellRenderer: idCellRenderer,
    },
    {
      field: "processId",
      headerComponent: CustomHeaderBuilder(
        "Process ID",
        <Grid3x3Icon className="header-icon" fontSize="small" />
      ),
      flex: 5,
      cellClass: "custom-cell",
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return `${props.data.processId}`;
        }
      },
    },
    {
      field: "createdDate",
      valueGetter: "node.id",

      headerComponent: CustomHeaderBuilder(
        "Timestamp Result",
        <AccessAlarmsIcon className="header-icon" fontSize="small" />
      ),
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return format(new Date(props.data.createdDate), "dd.MM.yyyy | HH:mm");
        }
      },
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "anomalyDetected",
      headerComponent: CustomHeaderBuilder(
        "AI Result",
        <HdrAutoIcon className="header-icon" fontSize="small" />
      ),
      flex: 3,
      cellRenderer: anomalyCellRenderer,
      cellClass: "custom-cell",
    },
  ];

  if (!hideArrow) {
    colDefs.push({
      flex: 1,
      cellRenderer: arrowCellRenderer,
      cellClass: "custom-cell",
    });
  }

  return colDefs;
};
