import NormalizedGraph from "../../components/normalizedGraph/graph";
import {
  DesignItCard,
  DesignItProgress,
} from "@design-it/react-library";
import moment from "moment";
import * as Amplify from "@aws-amplify/auth";
import { apiHelper } from "../../apis/apiHelper";
import { updateUploadCount } from "../../utils/uploadLimitHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Navigate } from "react-router";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect, useState } from "react";
import { useCSVContext } from "../../providers/CSVProvider";
import {
  downloadFromPresignedUrl,
  fetchPresignedUrl,
} from "../upload/uploadHelpers";
import { columnMapper } from "../dashboard/uploadHelper";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";
import { DataCheckModal } from "../../components/DataCheckModal/DataCheckModal";
import { Box, Typography } from "@mui/material";
import { Stepper } from "../dashboard/Stepper";
import { PageContent } from "../../components/PageContent/PageContent";

const UnloadedGraphBoxStyle = {
  width: "100%", 
  height: "100%", 
  display: "flex", 
  justifyContent: "center", 
  alignItems: "center", 
  flexDirection: "column"
};

export const DataCheckPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fileName = searchParams.get("fileName");
  const lastStep = searchParams.get("lastStep");
  const configName = searchParams.get("configName");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [jobId, setJobId] = useState<string>('');

  const { isExecuteTabAnalysis } = useStepperContext();
  const { lines, setLines } = useCSVContext();

  useEffect(() => {
    async function loadData() {
      if (!lines.length) {
        const preSignedUrl = await fetchPresignedUrl(fileName as string);
        const jsonData = await downloadFromPresignedUrl(preSignedUrl);
        const fileLines = jsonData.map(columnMapper);
        setLines(fileLines);
      }
    }
    loadData();
  }, []);

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  const onContinueClick = async () => {
    if (lastStep === "results") {
      navigate(`/results?fileName=${fileName}&configName=${configName}`);
    } else if (lastStep === "labelling") {
      navigate(`/labelling?fileName=${fileName}&configName=${configName}`);
    } else {
      const jobId = moment().format("YYYY-MM-DD_HH:mm:ss.SSS");
      setJobId(jobId);
      localStorage.setItem("jobId", jobId);
      const userId = (await Amplify.getCurrentUser())["userId"];
      const uploadBucketName =
        process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";

      apiHelper.post(
        process.env.REACT_APP_REST_API_ROOT_URL + "/start-workflow",
        {
          "user-id": userId,
          "bucket-name": uploadBucketName,
          "file-key": `raw/${fileName}`,
          "job-id": jobId,
          "config-name": `${configName}`,
        }
      );

      updateUploadCount(1, userId);
      handleOpen();
    }
  };

  const tabName = isExecuteTabAnalysis ? 'execute' : 'prepare';

  return (
    <PageContent
      title={"Validate the uploaded curves for " + (isExecuteTabAnalysis ? "analysis" : "training")}
      showButtonBar={true}
      backButton={{ handler: () => navigate(`/analysis?activeTab=${tabName}`) }}
      nextButton={{ handler: onContinueClick }}
    >
      <Box sx={{ height: "10%" }} data-testid="stepper-wrapper">
        <Stepper
          steps={
            isExecuteTabAnalysis
              ? MLFlowStepperLabelsExecuteTab
              : MLFlowStepperLabelsAnalysisTab
          }
          currentStep={2}
          shouldShowStepper={true}
        />
      </Box>

      <Box sx={{height: "90%", paddingBottom: "32px"}}>
        <DesignItCard
          state={undefined}
          className="bg-none h-full w-full max-w-full max-h-full min-w-full min-h-full"
        >
          {lines.length === 0 ? (
            <Box sx={UnloadedGraphBoxStyle}>
              <DesignItProgress indeterminate />
              <Typography>Building Graph...</Typography>
            </Box>
          ) : (
            <NormalizedGraph lines={lines} />
          )}
        </DesignItCard>

      </Box>

      {openModal && (
        <DataCheckModal
          open={openModal}
          handleClose={handleClose}
          jobId={jobId}
          fileName={fileName}
          configName={configName ?? 'config1'}
        />
      )}
    </PageContent>
  );
};
