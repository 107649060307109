import { Box, } from "@mui/material";
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { useMenuContext } from "../../providers/MenuProvider";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Logo } from "./Logo";
import { useNavigate } from "react-router-dom";
import { tertiaryColors } from "../../theme/DarkTheme";
import * as Auth from "@aws-amplify/auth";

const ContentContainerBoxStyle = { 
  bgcolor: tertiaryColors.dark, 
  position: "sticky", 
  height: "64px", 
  display: "flex", 
  justifyContent: "space-between", 
  padding: "0 18px" 
};

const IconsBoxStyle = {
  display: "flex", 
  alignItems: "center",
  cursor: "pointer"
};

export const MainAppBar = () => {
  const { setIsCollapsed, isCollapsed } = useMenuContext();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      // Redirect to the sign-in page or perform other actions after sign out
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <Box sx={ContentContainerBoxStyle} data-testid="app-bar">
      <Box gap={2} sx={IconsBoxStyle}>
        {isCollapsed 
          ? <MenuIcon onClick={() => setIsCollapsed(!isCollapsed)} data-testid="menu-icon" />
          : <CloseIcon onClick={() => setIsCollapsed(!isCollapsed)} data-testid="close-icon" />
        }
        <Logo clickHandler={() => navigate("/")} />
      </Box>

      <Box gap={1} sx={IconsBoxStyle}>
        <LoginOutlinedIcon onClick={handleLogout} data-testid="logout-icon" />
      </Box>
    </Box>
  );
};
