import { FC, useEffect } from "react";
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { useStepperContext } from "../../providers/StepperProvider";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { PageContent } from "../../components/PageContent/PageContent";
import { tertiaryColors } from "../../theme/DarkTheme";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { useAnalysisContext } from "../../providers/AnalysisProvider";

const firstBoxContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  gap: "40px"
};

const analysisBoxContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  borderRadius: "8px",
  padding: "60px 32px",
  backgroundColor: tertiaryColors.dark,
  width: "360px"
};

export const StartPage: FC = () => {
  const navigate = useNavigate();
  const { setShowStepper } = useStepperContext();
  const { cleanExecute, cleanPrepare } = useAnalysisContext();

  useEffect(() => {
    setShowStepper(false);
    cleanExecute();
    cleanPrepare();
  }, []);

  return (
    <PageContent
      showButtonBar={true}
      title="How can we help you today?"
      nextButton={{
        hide: true,
      }}
      backButton={{
        hide: true,
      }}
    >
      <Box sx={firstBoxContainer}>
        <Box sx={analysisBoxContainer}>
          <TroubleshootIcon sx={{ fontSize: "60px" }} />
          <Box sx={{ display: "flex", flexDirection:"column", alignItems: "center",  justifyContent: "center"}}>
            <Typography sx={{fontSize: "20px", marginBottom: "20px"}}> Unknown Error</Typography>
            <Button
              variant="outlined"
              onClick={() => navigate("/non-ml-flow-analysis")}>
              Analysis for a known error
            </Button>
          </Box>
        </Box>

        <Box sx={analysisBoxContainer}>
          <PsychologyOutlinedIcon sx={{ fontSize: "60px" }} />
          <Box sx={{ display: "flex", flexDirection:"column", alignItems: "center",  justifyContent: "center"}}>
            <Typography sx={{fontSize: "20px", marginBottom: "20px"}}> Known Error</Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/analysis")}
            >
              Analysis for an unknown error
            </Button>
          </Box>
        </Box>
      </Box>
    </PageContent >
  );
};
