import { tertiaryColors } from "../../theme/DarkTheme";
import "./style.scss";
import { Box, CircularProgress, Typography } from "@mui/material";

interface LoadingScreenProgressBarProps {
  value: number;
}

const DIMENSION = '160px';

export default function LoadingScreenProgressBar(
  props: LoadingScreenProgressBarProps
) {
  return (
    <Box
      className="loading-screen-progress-bar"
      sx={{ position: 'relative', height: DIMENSION, width: DIMENSION }}
    >
      <CircularProgress
        variant="determinate"
        size={DIMENSION}
        value={props.value * 99}
        thickness={1}
      />
      <Box
        height={'100%'}
        width={'100%'}
        top={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h6"
          component="div"
          fontWeight={100}
          sx={{ color: tertiaryColors.main }}
        >
          {`${Math.round(props.value * 99)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
