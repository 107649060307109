import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { DragEvent, RefObject, FC } from "react";
import UploadDialog from "../UploadDialog/UploadDialog";
import { useCSVContext } from "../../providers/CSVProvider";
import "./uploadDropContainer.scss";
import { S3 } from "aws-sdk";
import { Box, Button, Typography } from "@mui/material";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { primaryColors } from "../../theme/DarkTheme";

const ContentContainerBoxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 0px 12px 32px",
  width: "100%"
};

const UploadBaseBoxStyles = {
  fontWeight: 300,
  borderRadius: "12px",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: primaryColors.contrastText,
  width: "100%",
  height: "350px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const UploadDropContainerBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 2,
  height: "100%",
  width: "100%",
};

export interface UploadDropContainerProps {
  file: File | null;
  fileInputRef: RefObject<HTMLInputElement>;
  hasError?: boolean;
  setFile: (file: File | null) => void;
  verifyFile: (file: File) => Promise<void>;
  uploadHandler: S3.ManagedUpload | null;
  setShowUploadModal: (showUploadModal: boolean) => void;
  showUploadModal: boolean;
}

export const UploadDropContainer: FC<UploadDropContainerProps> = (props) => {
  const { setLines, progress, setProgress } = useCSVContext();

  const onBrowseFileClick = () => {
    props.fileInputRef.current?.click();
  };

  const handleDelete = () => {
    props.setFile(null);
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles: Array<File> = Array.from(event.dataTransfer.files);
    const errorMessages: string[] = [];

    if (droppedFiles.length > 0) {
      try {
        await props.verifyFile(droppedFiles[0]);
        props.setFile(droppedFiles[0]);
      } catch (err: unknown) {
        props.setFile(null);
        errorMessages.push(err as string);
      }
    }

    if (errorMessages.length > 0) {
      const errMsg = errorMessages.join("\n\n");
      alert(errMsg);
    }
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const newFile = event.target.files[0];

      try {
        await props.verifyFile(newFile);
        props.setFile(newFile);
      } catch (err: unknown) {
        props.setFile(null);
        alert(err);
      }
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const handleCancelUpload = () => {
    props.uploadHandler?.abort();
    setLines([]);
    props.setFile(null);
    setProgress(0);
    props.setShowUploadModal(false);
  };

  return (
    <>
      <Box sx={ContentContainerBoxStyle} >
        <Box
          sx={UploadBaseBoxStyles}
          className={`${props.hasError ? "border-error" : "border-outline"}`}>
          <input
            accept=".csv, text/csv"
            id="raised-button-file"
            type="file"
            onChange={onFileChange}
            className="hidden"
          />

          <Box
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={UploadDropContainerBoxStyle}
            data-testid="upload-drop-container"
          >
            {!props.file && (
              <UploadFileIcon htmlColor="#8EDA81" id="upload-file-icon" />
            )}

            {props.file && (
              <Box>
                <Typography >
                  File Name: {props.file.name}
                  <IconButton
                    onClick={() => handleDelete()}
                    color="error"
                    className="delete-file-button"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Typography>
              </Box>
            )}

            <Typography fontSize={20}>Drag & Drop your file</Typography>
            <Typography fontSize={16}>OR</Typography>

            <Button sx={{ width: "200px" }} variant="outlined" onClick={onBrowseFileClick}>
              <DriveFolderUploadOutlinedIcon sx={{ color: primaryColors.main }}></DriveFolderUploadOutlinedIcon>
              <Typography>Browse</Typography>
            </Button>

            <input
              type="file"
              ref={props.fileInputRef}
              onChange={onFileChange}
              onClick={onInputClick}
              className="hidden"
            />
          </Box>
        </Box>
        <p
          className={"w-fit text-error " + (props.hasError ? "" : " invisible")}
        >
          Please upload a file
        </p>
      </Box>

      {props.showUploadModal && (
        <UploadDialog
          uploadProgress={progress}
          handleCancelUpload={handleCancelUpload}
        />
      )}
    </>
  );
};
