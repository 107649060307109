import React from "react";
import LineChart from "./lineChart";
import { LineChartConfig } from "./lineChart";
import "./style.scss";

import { Button } from "@mui/material";
import { BoltingData } from "../../apis/types";

const combinedGraph = (
  rowData: BoltingData,
  showAngle: boolean
): LineChartConfig => {
  const angleValues = rowData.angleInDeg?.split(";").map(Number);
  const torqueValues = rowData.torqueInNm?.split(";").map(Number);
  const reconstructedValues = rowData.reconstructedTorque
    ?.split(";")
    .map(Number);
  const timeValues = rowData.timeInSec?.split(";").map(Number);
  const datasets = [
    {
      label: "Torque",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: torqueValues[i],
      })),
      borderColor: "#DE3730",
      backgroundColor: "#DE3730",
      borderWidth: 2,
      yAxisID: "y",
    },
    {
      label: "Torque reference curve",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: reconstructedValues[i],
      })),
      borderColor: "#8EDA81",
      backgroundColor: "#8EDA81",
      borderWidth: 2,
      yAxisID: "y",
    },
  ];

  if (showAngle) {
    datasets.push({
      label: "Angle",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: angleValues[i],
      })),
      borderColor: "#653EE4",
      backgroundColor: "#653EE4",
      borderWidth: 2,
      yAxisID: "y1",
    });
  }

  return {
    labels: timeValues,
    datasets: datasets,
    xTitle: "Time[s]",
    yTitle: ["Torque[Nm]", "Angle[°]"],
    title: "Torque and Angle over Time",
  };
};
interface CombinedGraphProps {
  row: BoltingData;
}

export default function CombinedGraph(props: CombinedGraphProps) {
  const [variant, setVariant] = React.useState<"contained" | "outlined">(
    "outlined"
  );

  const [showAngleGraph, setShowAngleGraph] = React.useState(false);
  const config = combinedGraph(props.row, showAngleGraph);

  const handleToggle = () => {
    setShowAngleGraph(prevState => !prevState);
    setVariant(prevVariant =>
      prevVariant === "contained" ? "outlined" : "contained"
    );
  };
  

  return (
    <div className="h-full ">
      <div className="h-[10%] flex items-center justify-end  ">
        <Button
          sx={{ width: "100px", marginRight: "20px" }}
          variant={variant}
          onClick={handleToggle}
        >
          Angle/Time
        </Button>
        <Button
          sx={{ width: "100px" }}
          variant="contained">
          Torque/Time
        </Button>
      </div>
      <div className="max-h-full h-[90%]">
        <LineChart config={config} />
      </div>
    </div>
  );
}
