import * as z from "zod";

const DEFAULT_ERROR = 'Please enter a valid name (letters and numbers only + underscore). Maximum 30 characters';

export const nonMLValidationSchema = () => z.object({
  toolId: z
    .string({
      required_error: "ToolId is requierd",
      invalid_type_error: "Incorect"
    })
    .regex(/[A-Za-z0-9\s_]+/,
      { message: DEFAULT_ERROR }
    ),
  processId: z
    .string({
      required_error: "ProcessId is required",
      invalid_type_error: "Incorrect"
    })
    .regex(
      /[A-Za-z0-9\s_]+/,
      { message: DEFAULT_ERROR }
    ),
  configName: z
    .string({
      required_error: "Configuration Name is required",
      invalid_type_error: "Incorrect"
    })
    .min(3)
    .regex(
      /[A-Za-z0-9\s_]{1,30}/,
      { message: DEFAULT_ERROR }
    ),
  targetTorque: z
    .string({
      required_error: 'Target Torque is required',
      invalid_type_error: 'Incorrect',
    })
    .regex(
      /[A-Za-z0-9\s_.,]+/,
      { message: DEFAULT_ERROR }
    ),
});

export type NonMLFlowAnalysisSchema = z.infer<ReturnType<typeof nonMLValidationSchema>>;
