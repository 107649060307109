import { DesignItStepper, DesignItStepperStep } from "@design-it/react-library";
import "./stepper.scss";

export type StepperProps = {
	shouldShowStepper: boolean;
	currentStep: number;
  steps: string[];
}

export function Stepper(props: StepperProps) {
  const getState = (stepperStep: number) => {
    if (stepperStep === props.currentStep) {
      return "current";
    } else if (stepperStep < props.currentStep) {
      return "success";
    } else {
      return "open";
    }
  };

  return (
    <>
      {props.shouldShowStepper && (
        <DesignItStepper className="pt-6 px-[22px]" data-testid='stepper'>
          {props.steps.map((step, index) => (
            <DesignItStepperStep key={index}
              state={getState(index + 1)}
              label={step} 
              {...(getState(index + 1 ) ==="success") && {icon: "checked"}}
            />
          ))}
        </DesignItStepper>
      )}
    </>
  );
}