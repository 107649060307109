import { Box, Typography } from "@mui/material";
import { primaryColors } from "../../theme/DarkTheme";

interface LogoProps {
  clickHandler?: () => void
}

export const Logo = (props: LogoProps) => {
  return (
    <Box
      sx={{ display: "flex", cursor: "default" }}
      onClick={props.clickHandler}
      data-testid="logo"
    >
      <Typography
        sx={{
          fontFamily: 'Orbitron, sans-serif',
          fontSize: '22px',
        }}
      >
        bolt
      </Typography>

      <Typography
        sx={{
          color: primaryColors.main,
          fontFamily: 'Orbitron, sans-serif',
          fontSize: '22px',
        }}
      >
        _it
      </Typography>
    </Box>
  );
};